import React from "react";
import PropTypes from "prop-types";
import NavigationHeader from "components/NavigationHeader";
import { Button, Badge, Tab, Tabs } from "@unite-us/app-components";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import { capitalize } from "lodash";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";

const FeeScheduleScreeningHeader = ({
  feeScheduleScreening,
  feeScheduleId,
  crumbs,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  return (
    <div
      className="flex flex-col items-start justify-center w-full border-b border-medium-border-blue"
      data-testid="fee-schedule-screening-header-container"
    >
      <NavigationHeader
        crumbsData={crumbs}
        backButtonRedirectTo={`/fee-schedules/${feeScheduleId}`}
        dataTestId="fee-schedule-screening-header-breadcrumbs"
      />

      <div className="px-20 w-full mt-4">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
              <h1 className="text-2xl">{feeScheduleScreening.name}</h1>
              <Badge
                className={`!leading-4 !text-sm px-4 mx-2 ${stateBgColor(feeScheduleScreening.state)}`}
                text={capitalize(feeScheduleScreening.state)}
                color=""
                style={textColorForStyleProp(feeScheduleScreening.state)}
              />
            </div>
            <div className="flex flex-row space-x-6">
              <Button
                id="edit-fee-schedule-screening-button"
                icon={{ name: "Edit", color: "text-blue", position: "left" }}
                className={
                  "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
                }
                label="Edit"
                data-testid="edit-fee-schedule-screening-button"
                disabled
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="flex items-center gap-2 text-gray-700">
            <div className="flex items-center">
              <span className="text-base font-semibold uppercase">
                Start Date:
              </span>
              <span className="ml-2 text-base">
                {moment
                  .utc(feeScheduleScreening.starts_at)
                  .format("MM/DD/YYYY")}
              </span>
            </div>
            <div className="mx-4 text-xl font-light">|</div>
            <div className="flex items-center">
              <span className="text-base font-semibold uppercase">
                End Date:
              </span>
              <span className="ml-2 text-base">
                {moment.utc(feeScheduleScreening.ends_at).format("MM/DD/YYYY")}
              </span>
            </div>
          </div>
        </div>

        <Tabs
          id="fee-schedule-screenings-tabs"
          className="mt-6"
          onChange={(value) =>
            navigate(
              `/fee-schedules/${feeScheduleId}/fee-schedule-screenings/${feeScheduleScreening.id}/${value}`,
            )
          }
          value={lastSegment}
        >
          <Tab
            id="fee-schedule-screening-overview-tab"
            label="Overview"
            value="overview"
            dataTestId="fee-schedule-screening-overview-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="fee-schedule-screening-cbo-table-tab"
            label="CBO Table"
            value="cbo-table"
            dataTestId="fee-schedule-screening-cbo-table-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
          <Tab
            id="fee-schedule-screening-programs-tab"
            label="Programs"
            value="programs"
            dataTestId="fee-schedule-screening-programs-tab"
            className="outline-0"
            // Added this since in app-components, onChange is required for Tab, but we don't need it here
            onChange={() => {}}
          />
        </Tabs>
      </div>
    </div>
  );
};

FeeScheduleScreeningHeader.propTypes = {
  feeScheduleScreening: PropTypes.object.isRequired,
  feeScheduleId: PropTypes.string.isRequired,
  crumbs: PropTypes.array.isRequired,
};

export default FeeScheduleScreeningHeader;
