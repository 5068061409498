import React, { useState } from "react";
import { useParams } from "react-router";
import { usePrograms } from "api/core/programHooks";
import PaginatedTable from "components/Tables/PaginatedTable";

const FeeScheduleScreeningPrograms = () => {
  const { feeScheduleScreeningId } = useParams();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: programsResponse,
    isFetching,
    isError,
  } = usePrograms({
    filters: { fee_schedule_screenings: feeScheduleScreeningId },
    pageSize: pageSize,
    pageNumber: pageNumber,
    include: "provider",
  });

  const { data: programs, paging } = programsResponse || {};

  const tableHeaders = [
    { label: "Program Name", className: "w-1/3" },
    { label: "Program ID", className: "w-1/3" },
    { label: "Organization Name", className: "w-1/3" },
  ];

  const tableBody = programs?.map((program) => ({
    rowId: program.id,
    rowData: [
      { data: program.name },
      { data: program.id },
      { data: program?.provider?.name },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue rounded-t-md">
        <h1 className="text-lg content-center">Programs</h1>
      </div>
      <PaginatedTable
        headers={tableHeaders}
        body={tableBody}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        paging={paging}
        dataTestId="fee-schedule-screening-programs-table"
        emptyTableMessage={`No programs linked to this Fee Schedule Screening.
        Click “Add Program” to add a program to this Fee Schedule Screening.`}
        errorMessage="Error Fetching Fee Schedule Screening Programs"
        className="!h-fit"
        emptyTableClassName="py-4"
      />
    </>
  );
};

export default FeeScheduleScreeningPrograms;
